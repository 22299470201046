import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import { ElNotification } from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import api from './service/axios.js'
import { calcTraffic, calcTime } from './service/common.js'
import BroadcastChannelManager from '@/service/BroadcastChannelManager';
import WebSocketManagerInstance from '@/service/WebSocketManager.js';

const app = createApp(App)
const navigate = (path) => {
  return router.push({ path: path })
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$nav = navigate
app.config.globalProperties.$notify = ElNotification
app.config.globalProperties.$api = api
app.config.globalProperties.$calcTraffic = calcTraffic
app.config.globalProperties.$calcTime = calcTime
app.config.globalProperties.$imgError = (e) => {
  //todo: report
}
const tabId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
const bcManager = new BroadcastChannelManager('fsm-channel', tabId)
app.config.globalProperties.$bc = bcManager
app.config.globalProperties.$ws = WebSocketManagerInstance
window.addEventListener('beforeunload', () => {
  bcManager.close()
});
app.use(router)
app.use(ElementPlus)
app.mount('#app')