import { reactive } from 'vue'

export const store = reactive({
    hadInit: false,
    backend: '/api',
    token: '',
    loadingCount: 0,
    salt: '',
    userInfo: {},
    userInfoUpdateAt: 0,
    mails: {},
    base: {},
    server: {},
    threads: {
        types: []
    },
    torrents: {
        types: [],
        systematics: [],
    }
})