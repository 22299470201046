import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/Users/login',
    name: 'LoginView',
    meta: { title: '登录' },
    component: () => import('../pages/users/LoginView.vue')
  },
  {
    path: '/Users/forgot',
    name: 'ForgotView',
    meta: { title: '重置密码' },
    component: () => import('../pages/users/ForgotView.vue')
  },
  {
    path: '/Users/reg',
    name: 'RegView',
    meta: { title: '注册' },
    component: () => import('../pages/users/RegView.vue')
  },
  {
    path: '/Users/me',
    name: 'ControlPanel',
    meta: { title: '控制面板' },
    component: () => import('../pages/users/ControlPanel.vue')
  },
  {
    path: '/Users/profile',
    name: 'ProfileView',
    meta: { title: '个人中心' },
    component: () => import('../pages/users/ProfileView.vue')
  },
  {
    path: '/Users/trustLogin',
    name: 'TrustLogin',
    meta: { title: '信任登录' },
    component: () => import('../pages/users/TrustLogin.vue')
  },
  {
    path: '/Forums',
    name: 'ForumsIndex',
    meta: { title: '论坛' },
    component: () => import('../pages/forums/IndexView.vue')
  },
  {
    path: '/Forums/new',
    name: 'NewThread',
    meta: { title: '发布新帖子', type: 'new' },
    component: () => import('../pages/forums/NewView.vue')
  },
  {
    path: '/Forums/edit',
    name: 'EditThread',
    meta: { title: '编辑帖子' },
    component: () => import('../pages/forums/EditView.vue')
  },
  {
    path: '/Forums/details',
    name: 'ForumsDetails',
    meta: { title: '帖子详情' },
    component: () => import('../pages/forums/DetailView.vue')
  },
  {
    path: '/Recommend',
    name: 'RecommendView',
    meta: { title: '猜你喜欢' },
    component: () => import('../pages/recommend/IndexView.vue')
  },
  {
    path: '/Torrents',
    name: 'TorrentsIndex',
    meta: { title: '种子' },
    component: () => import('../pages/torrents/IndexView.vue')
  },
  {
    path: '/Torrents/details',
    name: 'TorrentDetail',
    meta: { title: '种子详情' },
    component: () => import('../pages/torrents/DetailView.vue')
  },
  {
    path: '/Torrents/new',
    name: 'TorrentNew',
    meta: { title: '发布种子', type: 'new' },
    component: () => import('../pages/torrents/NewView.vue')
  },
  {
    path: '/Torrents/edit',
    name: 'TorrentEdit',
    meta: { title: '编辑种子', type: 'edit' },
    component: () => import('../pages/torrents/NewView.vue')
  },
  {
    path: '/Torrents/mine',
    name: 'MyTorrentView',
    component: () => import('../pages/torrents/MyTorrent.vue')
  },
  {
    path: '/Actress/details',
    name: 'ActressDetail',
    meta: { title: '演员详情' },
    component: () => import('../pages/actress/DetailView.vue')
  },
  {
    path: '/Votes',
    name: 'VotesIndex',
    meta: { title: '自治委员会' },
    component: () => import('../pages/votes/IndexView.vue')
  },
  {
    path: '/Votes/details',
    name: 'VotesDetail',
    meta: { title: '投票详情' },
    component: () => import('../pages/votes/DetailView.vue')
  },
  {
    path: '/Votes/new',
    name: 'NewVote',
    meta: { title: '发起新投票' },
    component: () => import('../pages/votes/NewView.vue')
  },
  {
    path: '/Achievements',
    name: 'AchievementsIndex',
    meta: { title: '成就' },
    component: () => import('../pages/achievements/IndexView.vue')
  },
  {
    path: '/Rules',
    name: 'RulesIndex',
    meta: { title: '规则' },
    component: () => import('../pages/rules/IndexView.vue')
  },
  {
    path: '/Rss',
    name: 'RssIndex',
    meta: { title: 'RSS' },
    component: () => import('../pages/rss/IndexView.vue')
  },
  {
    path: '/Points',
    name: 'PointsIndex',
    meta: { title: '魔力' },
    component: () => import('../pages/points/IndexView.vue')
  },
  {
    path: '/Invites',
    name: 'InvitesIndex',
    meta: { title: '邀请' },
    component: () => import('../pages/invites/IndexView.vue')
  },
  {
    path: '/Mail',
    name: 'MailIndex',
    meta: { title: '站内信' },
    component: () => import('../pages/mails/IndexView.vue')
  },
  {
    path: '/Mail/send',
    name: 'MailSend',
    meta: { title: '站内信' },
    component: () => import('../pages/mails/SendView.vue')
  },
  {
    path: '/Datas',
    name: 'DataIndex',
    meta: { title: '数据统计' },
    component: () => import('../pages/datas/IndexView.vue')
  },
  {
    path: '/Datas/debug',
    name: 'DataDebug',
    meta: { title: '调试数据' },
    component: () => import('../pages/datas/DebugView.vue')
  },
  {
    path: '/API',
    name: 'ApiIndex',
    meta: { title: 'API接口' },
    component: () => import('../pages/api/IndexView.vue')
  },
  {
    path: '/VideoList',
    name: 'VideoListIndex',
    meta: { title: '片单' },
    component: () => import('../pages/videolist/IndexView.vue')
  },
  {
    path: '/VideoList/new',
    name: 'VideoListNew',
    meta: { title: '新建片单' },
    component: () => import('../pages/videolist/NewView.vue')
  },
  {
    path: '/VideoList/details',
    name: 'VideoListDetail',
    meta: { title: '片单详情' },
    component: () => import('../pages/videolist/DetailView.vue')
  },
  {
    path: '/VideoList/addToList',
    name: 'addToVideoList',
    meta: { title: '添加内容到片单' },
    component: () => import('../pages/videolist/AddToList.vue')
  },
  {
    path: '/VideoList/admin',
    name: 'VideoListAdmin',
    meta: { title: '片单管理' },
    component: () => import('../pages/videolist/AdminView.vue')
  },
  {
    path: '/Honor',
    name: 'HonorIndex',
    meta: { title: '荣誉' },
    component: () => import('../pages/honor/IndexView.vue')
  },
  {
    path: '/Frp',
    name: 'FrpIndex',
    meta: { title: 'FRP 内网穿透' },
    component: () => import('../pages/frp/IndexView.vue')
  },
  {
    path: '/',
    name: 'RootAlias',
    redirect: '/Recommend'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
