<template>
    <div class="txt-center">
        <div>
            <el-text class="mx-1" type="info">
                &copy; 2022 - {{ year }}, Powered by {{ copyright }}
            </el-text>
        </div>
        <div>
            <el-text class="mx-1" type="info">
                <span v-if="ifLogin">
                    <el-link :underline="false" type="primary" href="/API" target="_blank">API</el-link>
                    <el-divider direction="vertical" />
                    <el-link :underline="false" type="primary" href="/Datas" target="_blank">analysis</el-link>
                    <el-divider direction="vertical" />
                </span>
                <el-link :underline="false" type="primary" :href="tgLink" target="_blank">加入聊天群</el-link>
            </el-text>
        </div>
    </div>
</template>

<script>
import { store } from '@/service/store.js'

export default {
    data() {
        return {
            copyright: 'FSM'
        }
    },
    computed: {
        year() {
            const time = new Date()
            return time.getFullYear()
        },
        ifLogin() {
            if (store.token) {
                return true
            }
            return false
        },
        tgLink() {
            return store.base.tgGroup
        }
    }
}
</script>

<style></style>