<template>
  <div class="common-layout">
    <el-container>
      <el-main>
        <el-row>
          <el-col :xs="0" :sm="2" :md="3" :lg="3" :xl="3">
          </el-col>
          <el-col :xs="24" :sm="20" :md="18" :lg="18" :xl="18">
            <HeaderView v-if="ifShowHeader"></HeaderView>
            <el-row v-if="ifShowHeader && siteFree && siteFree.enable === true" justify="center">
              <el-col :xs="24" :sm="20" :md="8" :lg="8" :xl="8">
                <el-alert :center="true" :title="'站免中，截止至 ' + siteFree.endAt" type="success" :closable="false" />
              </el-col>
            </el-row>
            <router-view v-slot="{ Component }" v-loading="loadingCount > 0" class="margin-top-8">
              <component ref="currentPage" :is="Component" />
            </router-view>
          </el-col>
          <el-col :xs="0" :sm="2" :md="3" :lg="3" :xl="3">
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <FooterView></FooterView>
      </el-footer>
      <el-row class="side-blk">
        <div>
          <el-tooltip class="box-item" effect="dark" content="刷新页面" placement="left">
            <el-button @click="refreshPage" class="side-btn" size="large" type="info" icon="Refresh" circle plain />
          </el-tooltip>
        </div>
        <div>
          <el-tooltip class="box-item" effect="dark" content="页面最顶部" placement="left">
            <el-button @click="scrollToTop" class="side-btn" size="large" type="info" icon="ArrowUpBold" circle plain />
          </el-tooltip>
        </div>
        <div>
          <el-tooltip class="box-item" effect="dark" content="页面最底部" placement="left">
            <el-button @click="scrollToBottom" class="side-btn" size="large" type="info" icon="ArrowDownBold" circle
              plain />
          </el-tooltip>
        </div>
      </el-row>
    </el-container>
  </div>
</template>


<script>
import { ElMessage } from 'element-plus'
import { store } from '@/service/store.js'
import { ref } from 'vue'
import HeaderView from '@/pages/base/Header.vue'
import FooterView from '@/pages/base/FooterView.vue'
import 'element-plus/theme-chalk/dark/css-vars.css'

export default {
  name: 'HomePage',
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      siteFree: ref({})
    }
  },
  setup() {
    if (localStorage) {
      store.token = localStorage.getItem('token')
    }
    if (!store.token) {
      let path = window.location.pathname
      let ignorePath = [
        '/users/login',
        '/users/forgot',
        '/users/reg',
        '/datas/debug',
        '/users/trustlogin',
      ]
      let length = ignorePath.length
      let allowNotLogin = false
      for (var i = 0; i < length; i++) {
        if (ignorePath[i] === path.toLowerCase()) {
          allowNotLogin = true
          break
        }
      }
      if (allowNotLogin === false) {
        let redirect = ''
        if (window.location.pathname) {
          redirect = window.location.pathname
        }
        if (window.location.search) {
          redirect += window.location.search
        }
        if (redirect) {
          window.location.href = '/Users/login?_uri=' + encodeURIComponent(redirect)
        } else {
          window.location.href = '/Users/login'
        }
      }
    }
  },
  created() {
    this.$api.get('/Config/base')
      .then(res => {
        if (res && res.success) {
          store.hadInit = true
          store.server = res.data.server
          store.base = {
            regType: res.data.regType,
            sitename: res.data.sitename,
            point: res.data.point,
            tgGroup: res.data.tgGroup,
            ip: res.data.ip,
            ipLocation: res.data.ipLocation
          }
          document.title = document.title + ' - ' + store.base.sitename
          if (res.data.siteFree.enable === true) {
            this.$data.siteFree = ref(res.data.siteFree)
          }
          if (res.data.ipLocation === 'CN') {
            ElMessage({
              message: '请使用代理访问本站',
              type: 'error',
              duration: 0,
            })
          }
        }
      })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (to.path === '/') {
          return
        }
        if (to.meta.title) {
          document.title = to.meta.title
          if (store.base.sitename) {
            document.title = document.title + ' - ' + store.base.sitename
          }
        } else {
          document.title = store.base.sitename
        }
        this.updateBaseInfo()
      }
    }
  },
  computed: {
    ifShowHeader() {
      let path = window.location.pathname
      let ignorePath = [
        '/users/login',
        '/users/forgot',
        '/users/reg',
        '/datas/debug',
        '/users/trustlogin',
      ]
      let length = ignorePath.length
      for (var i = 0; i < length; i++) {
        if (ignorePath[i] === path.toLowerCase()) {
          return false;
        }
      }
      return true;
    },
    loadingCount() {
      return store.loadingCount
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    },
    scrollToBottom() {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    },
    refreshPage() {
      if (this.$refs.currentPage && this.$refs.currentPage.init) {
        this.$refs.currentPage.init()
      } else {
        window.location.reload()
      }
    },
    updateBaseInfo() {
      if (store.token) {
        let nowTime = (Date.parse(new Date()) / 1000)
        if (nowTime - store.userInfoUpdateAt > 300) {
          this.$api.get('/Users/infos').then(res => {
            if (res && res.success) {
              store.userInfo = res.data
              store.userInfoUpdateAt = (Date.parse(new Date()) / 1000)
            }
          })
          this.$api.get('/Mail/countUnRead').then(res => {
            if (res && res.success) {
              store.mails.unRead = res.data.count
            }
          })
        }
      }
    },
    handleStorageEvent(event) {
      if (event.key === 'ws_connected') {
        if (event.newValue === 'true') {
          this.$ws.isConnected = true;
        } else if (event.oldValue === 'true' && !event.newValue) {
          this.$ws.isConnected = false;
          this.$ws.ws = null;
        }
      } else if (event.key === 'ws_reconnecting' && event.newValue === 'false') {
        if (!this.$ws.isConnected) {
          this.$ws.reconnect();
        }
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.flex {
  display: flex;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

.font-des {
  font-size: 12px;
  color: #73767a;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-12 {
  margin-top: 12px;
}

.no-pad {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.break-word {
  word-break: break-word;
}

p {
  margin: 0;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

img {
  max-width: 100%;
}

.main-content {
  word-break: break-word;
  overflow: hidden;
}

.txt-bold {
  font-weight: bold;
}

.font-18 {
  font-size: 18px;
}

a {
  text-decoration: none !important;
  color: var(--el-color-primary);
}

a:hover {
  color: var(--el-color-primary-light-3);
}

.banUser {
  color: var(--el-color-info-light-3) !important;
  text-decoration: line-through !important
}

.limitedUser {
  color: var(--el-color-info-light-3) !important
}

.normalUser {
  color: var(--el-color-info-dark-2) !important
}

.vipUser {
  color: var(--el-color-success-light-3) !important
}

.adminUser {
  color: var(--el-color-danger-light-3) !important
}

.pad8 {
  padding: 8px;
}

.pad20 {
  padding: 20px;
}

.side-blk {
  position: fixed;
  right: 20px;
  bottom: 60px;
  width: 32px;
  z-index: 999;
}

.side-btn {
  margin-bottom: 6px;
}

.ql-content-fix {
  padding: 0 !important;
  white-space: inherit !important;
  min-height: 23px !important;
}

.waterfall-list {
  background-color: var(--el-bg-color) !important;
}

.img-beautify {
  -webkit-filter: saturate(105%) brightness(1.03);
  -moz-filter: saturate(105%) brightness(1.03);
  -o-filter: saturate(105%) brightness(1.03);
  filter: saturate(105%) brightness(1.03);
}
</style>
