class WebSocketManager {
    constructor() {
        if (WebSocketManager.instance) {
            return WebSocketManager.instance;
        }
        this.url = window.location.hostname === 'fsm.name' ? 'wss://fsm.name/ws' : 'wss://dev.fsm.name/ws';
        this.websocket = null;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectDelay = 500;
        this.heartbeatInterval = 10000;
        this.heartbeatTimer = null;
        this.token = localStorage.getItem('token');
        if (this.token) {
            this.initWebSocket();
        }

        window.addEventListener('online', () => this.handleNetworkChange(true));
        window.addEventListener('offline', () => this.handleNetworkChange(false));
        WebSocketManager.instance = this;
    }

    static getInstance() {
        if (!WebSocketManager.instance) {
            WebSocketManager.instance = new WebSocketManager();
        }
        return WebSocketManager.instance;
    }

    initWebSocket() {
        try {
            this.websocket = new WebSocket(this.url);
            this.websocket.onopen = () => {
                this.reconnectAttempts = 0;
                this.startHeartbeat();
            };
            this.websocket.onmessage = this.handleMessage.bind(this);
            this.websocket.onclose = (event) => {
                this.stopHeartbeat();
                if (event.code !== 1000) {
                    this.reconnect();
                }
            };
            this.websocket.onerror = (error) => {
                this.reconnect();
            };
        } catch (error) {
            this.reconnect();
        }
    }

    handleMessage(event) {
        const message = JSON.parse(event.data);
        switch (message.type) {
            case 'logout':
                localStorage.removeItem('token')
                localStorage.removeItem('DeviceId')
                return window.location.href = '/Users/login'
            case 'hi':
                if (message.deviceId) {
                    if (localStorage.getItem('DeviceId') !== message.deviceId) {
                        localStorage.setItem('DeviceId', message.deviceId);
                    }
                }
                break;
            default:
        }
    }

    reconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts && this.token) {
            this.reconnectAttempts++;
            setTimeout(() => {
                this.initWebSocket();
            }, this.reconnectDelay);
        }
    }

    send(message) {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            try {
                this.websocket.send(JSON.stringify({
                    action: message,
                    token: localStorage.getItem('token'),
                }));
            } catch (error) {
                this.reconnect();
            }
        } else {
            this.reconnect();
        }
    }

    startHeartbeat() {
        const sendHeartbeat = () => {
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                this.send('hello');
            } else {
                this.reconnect();
            }
            this.heartbeatTimer = setTimeout(sendHeartbeat, this.heartbeatInterval);
        }
        if (!this.heartbeatTimer) {
            sendHeartbeat();
        }
    }

    stopHeartbeat() {
        if (this.heartbeatTimer) {
            clearTimeout(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
    }

    handleNetworkChange(isOnline) {
        if (isOnline) {
            this.reconnectAttempts = 0;
            this.reconnect();
        }
    }

    closeWebSocket() {
        if (this.websocket) {
            this.websocket.close(1000, '客户端主动关闭');
        }
        this.stopHeartbeat();
    }
}

const instance = WebSocketManager.getInstance();
export default instance;
