class BroadcastChannelManager {
    constructor(channelName = 'default-channel', tabId = 'default-tab') {
        if (!BroadcastChannelManager.instance) {
            this.channel = new BroadcastChannel(channelName);
            this.tabId = tabId;
            this.init();
            BroadcastChannelManager.instance = this;
        }
        this.checkTimer = null;
        this.heartbeatTimer = null;
        this.checkInterval = 500;
        this.heartbeatInterval = 500;
        this.heartbeatDelay = 1500;
        this.lastHeartbeatTime = Date.now() - 500;
        return BroadcastChannelManager.instance;
    }

    init() {
        this.channel.onmessage = this.handleMessage.bind(this);
        this.post({ type: 'init' })
        this.checkHeartbeat();
    }

    startHeartbeat() {
        this.stopCheck();
        const sendHeartbeat = () => {
            if (this.tabId !== localStorage.getItem('main_tab')) {
                this.stopHeartbeat()
                return
            } else {
                this.post({ type: 'hello' })
            }
            this.heartbeatTimer = setTimeout(sendHeartbeat, this.heartbeatInterval);
        }

        if (!this.heartbeatTimer) {
            localStorage.setItem('main_tab', this.tabId);
            sendHeartbeat();
        }
    }

    stopHeartbeat() {
        if (this.heartbeatTimer) {
            clearTimeout(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
        this.checkHeartbeat();
    }

    checkHeartbeat() {
        const checkStatus = () => {
            if (this.tabId !== localStorage.getItem('main_tab')) {
                const currentTime = Date.now();
                if (currentTime - this.lastHeartbeatTime > this.heartbeatDelay) {
                    this.startHeartbeat();
                    return;
                }
            }
            this.checkTimer = setTimeout(checkStatus, this.checkInterval);
        }
        if (!this.checkTimer) {
            checkStatus();
        }
    }

    stopCheck() {
        if (this.checkTimer) {
            clearTimeout(this.checkTimer);
            this.checkTimer = null;
        }
    }

    handleMessage(event) {
        const message = event.data;
        switch (message.type) {
            case 'init':
                if (this.tabId === localStorage.getItem('main_tab')) {
                    this.post({ type: 'hello' })
                }
                break;
            case 'hello':
                this.lastHeartbeatTime = Date.now();
                if (message.tabId === localStorage.getItem('main_tab')) {
                    this.stopHeartbeat();
                }
                break;
            case 'destory':
                this.startHeartbeat();
                break;
            default:
                console.log(message);
        }
    }

    post(msg) {
        if (this.channel) {
            msg.tabId = this.tabId;
            this.channel.postMessage(msg);
        }
    }

    close() {
        if (this.tabId === localStorage.getItem('main_tab')) {
            this.post({ type: 'destory' });
            this.stopHeartbeat();
        }
        this.channel.close();
    }
}

export default BroadcastChannelManager;