<template>
    <div>
        <el-row justify="end" align="middle" class="h-m-b-20 hidden-xs-only">
            <div>
                <el-row justify="end" align="middle">
                    <div v-html="userInfo.profile"></div>
                    <el-divider direction="vertical" />
                    <el-dropdown :tabindex="-1">
                        <el-text size="small" class="fix-hover">
                            <el-text>我的</el-text>
                            <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon>
                        </el-text>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <el-link href="/Torrents/mine?type=posted" :underline="false">已发布</el-link>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-link href="/Torrents/mine?type=favorite" :underline="false">已收藏</el-link>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-link href="/Torrents/mine?type=download" :underline="false">下载中</el-link>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-link href="/Torrents/mine?type=seed" :underline="false">做种中</el-link>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-divider direction="vertical" />
                    <el-dropdown :tabindex="-1">
                        <el-text size="small" class="fix-hover">
                            <el-text>服务</el-text>
                            <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon>
                        </el-text>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <el-link href="/Frp" :underline="false">内网穿透</el-link>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-divider direction="vertical" />
                    <el-badge :value="messageUnRead" class="item" v-if="messageUnRead > 0">
                        <el-link href="/Mail" :underline="false">
                            <el-text>站内信</el-text>
                        </el-link>
                    </el-badge>
                    <el-link href="/Mail" :underline="false" v-else>
                        <el-text>站内信</el-text>
                    </el-link>
                    <el-divider direction="vertical" />
                    <el-link href="/Users/me" :underline="false">
                        <el-text>设置</el-text>
                    </el-link>
                    <el-divider direction="vertical" />
                    <el-link href="/Invites" :underline="false">
                        <el-text>邀请</el-text>
                    </el-link>
                    <el-divider direction="vertical" />
                    <el-link :underline="false">
                        <el-switch v-model="darkMode" active-action-icon="Moon" inactive-action-icon="Sunny"
                            @change="darkModeChange" />
                    </el-link>
                    <el-divider direction="vertical" />
                    <el-link @click="logout" :underline="false">
                        <el-text>退出登录</el-text>
                    </el-link>
                </el-row>
                <el-row justify="end" align="middle">
                    <el-link :underline="false" v-if="userInfo.peers" target="_blank" href="/Torrents/mine?type=seed">
                        <el-icon color="var(--el-color-success)">
                            <Top />
                        </el-icon>
                        <el-text size="small">
                            {{ userInfo.peers.upload }}
                        </el-text>
                    </el-link>
                    <el-link :underline="false" v-if="userInfo.peers" target="_blank" href="/Torrents/mine?type=download">
                        <el-icon color="var(--el-color-danger)">
                            <Bottom />
                        </el-icon>
                        <el-text size="small">
                            {{ userInfo.peers.download }}
                        </el-text>
                    </el-link>
                    <el-divider direction="vertical" />
                    <el-dropdown :tabindex="-1">
                        <el-text size="small" class="fix-hover">
                            Buffer：{{ $calcTraffic(userInfo.buffer, 1) }}
                            <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon>
                        </el-text>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>
                                    <el-text size="small">上传量：{{ $calcTraffic(userInfo.upload, 1) }}</el-text>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-text size="small">下载量：{{ $calcTraffic(userInfo.download, 1) }}</el-text>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-divider direction="vertical" v-if="userInfo.free && userInfo.free.hasFree" />
                    <el-dropdown :tabindex="-1" v-if="userInfo.free && userInfo.free.hasFree">
                        <el-text size="small" class="fix-hover">
                            免费流量：{{ $calcTraffic(userInfo.free.total, 1) }}
                            <el-icon class="el-icon--right">
                                <arrow-down />
                            </el-icon>
                        </el-text>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item v-for="free in userInfo.free.list">
                                    <el-text size="small">{{ $calcTraffic(free.used, 1) }} / {{ $calcTraffic(free.traffic, 1) }}</el-text>
                                    <el-text size="small">（{{ free.endAt }}）</el-text>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <el-divider direction="vertical" />
                    <el-tooltip class="box-item" effect="dark"
                        content="本月保种时G = SUM(每个种子体积[单位：GB] X 该种本自然月保种时间[单位：秒]) ÷ 30天 ÷ 24小时 ÷ 60分钟 ÷ 60秒"
                        placement="bottom">
                        <el-text size="small">保种时G：{{ userInfo.seedGH }}</el-text>
                    </el-tooltip>
                    <el-divider direction="vertical" />
                    <el-link :underline="false" href="/Points">
                        <el-text size="small">魔力值：{{ userInfo.point }}</el-text>
                    </el-link>
                </el-row>
            </div>
            <div class="h-m-l-12">
                <el-avatar class="h-avatar" :size="64" shape="square" :src="userInfo.avatar" />
            </div>
        </el-row>
        <el-menu :default-active="activeIndex" class="h-m-b-20 h-nav" mode="horizontal" :router="true">
            <el-menu-item v-for="item in routes" :index="item.path">{{ item.name }}</el-menu-item>
            <el-sub-menu index="99" class="hidden-sm-and-up">
                <template #title>{{ userInfo.username }}</template>
                <el-menu-item :index="'/Users/profile?uid=' + userInfo.uid">个人信息</el-menu-item>
                <el-menu-item index="/Users/me">控制面板</el-menu-item>
                <el-badge :value="messageUnRead" class="item" v-if="messageUnRead > 0">
                    <el-menu-item index="/Mail">站内信</el-menu-item>
                </el-badge>
                <el-menu-item index="/Mail" v-else>站内信</el-menu-item>
                <el-menu-item index="/Invites">邀请</el-menu-item>
            </el-sub-menu>
        </el-menu>
    </div>
</template>

<script>
import { ref } from 'vue'
import { store } from '@/service/store.js'
import { useDark, useToggle } from '@vueuse/core'
import 'element-plus/theme-chalk/display.css'

export default {
    data() {
        return {
            routes: [
                {
                    name: '首页',
                    path: '/Recommend',
                },
                {
                    name: '论坛',
                    path: '/Forums',
                },
                {
                    name: '种子',
                    path: '/Torrents',
                },
                {
                    name: '片单',
                    path: '/VideoList',
                },
                {
                    name: '自治委员会',
                    path: '/Votes',
                },
                {
                    name: '成就系统',
                    path: '/Achievements',
                },
                {
                    name: '荣誉',
                    path: '/Honor',
                },
                {
                    name: '规则',
                    path: '/Rules',
                },
                {
                    name: 'RSS',
                    path: '/Rss',
                },
            ],
            activeIndex: ref(null),
            darkMode: ref(false),
        }
    },
    computed: {
        userInfo() {
            return store.userInfo
        },
        messageUnRead() {
            if (!store.mails.unRead) {
                return 0
            } else {
                return store.mails.unRead
            }
        },
        isAutoDark() {
            const width = window.innerWidth
            if (width < 768) {
                return true
            } else {
                return false
            }
        }
    },
    beforeMount() {
        this.$data.activeIndex = window.location.pathname
    },
    mounted() {
        if (this.isAutoDark) {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.$data.darkMode = ref(true)
            }
        } else {
            if (localStorage) {
                let displayMode = localStorage.getItem('display-mode')
                if (displayMode === 'dark') {
                    this.$data.darkMode = ref(true)
                } else {
                    this.$data.darkMode = ref(false)
                }
            } else {
                this.$data.darkMode = ref(false)
            }
        }
        this.darkModeChange(this.$data.darkMode)
    },
    methods: {
        logout() {
            this.$api.post('/Users/logout').then(res => {
                if (res && res.success) {
                    store.token = ''
                    store.userInfo = {}
                    store.userInfoUpdateAt = 0
                    window.location.href = '/Users/login'
                }
            })
        },
        darkModeChange(isDarkMode) {
            const isDark = useDark({
                selector: 'html',
                attribute: 'class',
                valueDark: 'dark',
                valueLight: 'light'
            })
            if (localStorage) {
                if (this.$data.darkMode === true) {
                    localStorage.setItem('display-mode', 'dark')
                } else {
                    localStorage.setItem('display-mode', 'light')
                }
            }
            const toggleDark = useToggle(isDark)
            toggleDark(isDarkMode)
        }
    }
}
</script>

<style>
.h-avatar {
    padding: 2px;
    background: #FFF;
    border: 1px solid;
    border-color: #F2F2F2 #CDCDCD #CDCDCD #F2F2F2;
    box-shadow: var(--el-box-shadow-lighter);
}

.h-m-b-20 {
    margin-bottom: 20px;
}

.h-m-l-12 {
    margin-left: 12px;
}

.h-nav {
    box-shadow: var(--el-box-shadow-light);
}

.fix-hover {
    outline: none !important;
    cursor: pointer;
}
</style>