export const calcTraffic = (traffic, offset = 0) => {
    if (typeof traffic !== 'number' && parseInt(traffic) != traffic) {
        return traffic
    }
    let isNegative = (traffic < 0)
    if (isNegative) {
        traffic = -traffic
    }
    const units = [
        'KB',
        'MB',
        'GB',
        'TB',
        'PB',
        'EB',
        'ZB',
        'YB',
        'BB',
    ]
    let calcCount = 0
    if (traffic > 0) {
        while ((traffic / 1024) > 1) {
            traffic = traffic / 1024
            calcCount++
        }
    }
    if (calcCount - offset < 0) {
        return '0 B'
    }
    if (isNegative) {
        traffic = -traffic
    }
    return traffic.toFixed(2) + ' ' + units[calcCount - offset]
}

export const calcTime = (ts) => {
    let now = Math.floor(Date.now() / 1000)
    let offset = 0
    let suffix = ''
    if (now > ts) {
        offset = (now - ts)
        suffix = '前'
    } else if (ts > now) {
        offset = (ts - now)
        suffix = '后'
    }
    let tStr = ''
    if (offset < 60) {
        suffix = '刚刚'
    } else if (offset < 3600) {
        tStr = Math.floor(offset / 60) + '分钟'
    } else if (offset < 86400) {
        tStr = Math.floor(offset / 3600) + '时'
    } else if (offset < 7776000) {
        tStr = Math.floor(offset / 86400) + '日'
    } else if (offset < 31536000) {
        let m = Math.floor(offset / 2592000)
        let d = Math.floor((offset - (m * 2592000)) / 86400)
        tStr = m + '月' + d + '日'
    } else {
        let y = Math.floor(offset / 31536000)
        let m = Math.floor((offset - (y * 31536000)) / 2592000)
        tStr = y + '年' + m + '月'
    }
    return tStr + suffix
}

import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { store } from './store.js'
import md5 from 'js-md5'
export const getvisitorId = async () => {
    if (store && store.salt && store.salt !== '') {
        return store.salt
    } else {
        if (!ifPwa()) {
            const fpPromise = FingerprintJS.load()
            const fp = await fpPromise
            const result = await fp.get()
            const { fonts, plugins, ...components } = result.components
            const visitorId = FingerprintJS.hashComponents(components)
            if (localStorage) {
                let hardware = {}
                if (localStorage.getItem('hardware') != null) {
                    hardware = JSON.parse(localStorage.getItem('hardware'))
                }
                hardware[visitorId] = components
                localStorage.setItem('hardware', JSON.stringify(hardware))
            }
            store.salt = visitorId
            return visitorId
        } else {
            return md5('pwa:' + window.navigator.userAgent)
        }
    }
}

export const ifPwa = () => {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    )
}